import React, { useState, useEffect } from 'react';
import './BossTracker.css'; // Create this CSS file for styling
import { FaTrophy } from 'react-icons/fa'; // Import a trophy icon

// Boss data with names, statuses, rank types, and realm ranks
const bossData = [
  { name: 'Shannox', isAlive: false, rankType: 'World', realmRank: 1 },
  { name: 'Lord Rhyolith', isAlive: false, rankType: 'Realm', realmRank: 3 },
  { name: 'Alysrazor', isAlive: false, rankType: 'Realm', realmRank: 2 },
  { name: 'Beth\'tilac', isAlive: false, rankType: 'Realm', realmRank: 3 },
  { name: 'Baleroc', isAlive: false, rankType: 'Realm', realmRank: 3 },
  { name: 'Majordomo', isAlive: false, rankType: 'Realm', realmRank: 3 },
  { name: 'Ragnaros', isAlive: false, rankType: 'Realm', realmRank: 2, worldRank: 21 },
];

// Function to get ordinal suffix
const getOrdinalSuffix = (rank) => {
  const j = rank % 10,
        k = rank % 100;
  if (j === 1 && k !== 11) return `${rank}st`;
  if (j === 2 && k !== 12) return `${rank}nd`;
  if (j === 3 && k !== 13) return `${rank}rd`;
  return `${rank}th`;
};

// Function to determine trophy color based on rank
const getTrophyColor = (rank) => {
  if (rank === 1) return 'gold';
  if (rank === 2) return 'silver';
  if (rank === 3) return 'bronze';
  return null;
};

const BossTracker = () => {
  const [bosses] = useState(bossData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if the modal has already been shown
    const modalSeen = localStorage.getItem('modalSeen');
    if (!modalSeen) {
      checkForChampion();
    }
  }, [bosses]);

  // Open modal if Ragnaros has world or realm rank 1 and hasn't been shown before
  const checkForChampion = () => {
    const ragnaros = bosses.find(boss => boss.name === 'Ragnaros');
    if (ragnaros && ragnaros.realmRank === 1) {
      setIsModalOpen(true);
      localStorage.setItem('modalSeen', 'true');
    }
  };

  // Close modal when clicking outside of it
  const handleModalClick = (e) => {
    if (e.target.className === 'modal') {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="boss-tracker">
      {bosses.map((boss, index) => (
        <div key={index} className="boss">
          <div className="boss-icon">
            <img 
              src={`${process.env.PUBLIC_URL}/BossTracker/${boss.name}.png`} 
              alt={boss.name}
            />
            {!boss.isAlive && (
              <img
                src={`${process.env.PUBLIC_URL}/BossTracker/Killed.png`}
                alt="Killed"
                className="killed-overlay"
              />
            )}
          </div>
          <div className="boss-name">
            {getTrophyColor(boss.realmRank) && (
              <FaTrophy className={`trophy-icon ${getTrophyColor(boss.realmRank)}`} />
            )}
            {boss.name}
          </div>
          <div className="boss-status">
            {boss.isAlive ? (
              <span className="alive-text">Alive</span>
            ) : (
              boss.name === 'Ragnaros' ? (
                <span>
                  World {getOrdinalSuffix(boss.worldRank)} / Realm {getOrdinalSuffix(boss.realmRank)} (10)
                </span>
              ) : (
                `${boss.rankType} ${getOrdinalSuffix(boss.realmRank)} (10)`
              )
            )}
          </div>
        </div>
      ))}

      {/* Modal for We Are the Champions */}
      {isModalOpen && (
        <div className="modal" onClick={handleModalClick}>
          <div className="modal-content">
            <h2><FaTrophy className="trophy-icon gold" /> We are the champions!</h2>
            <p>World First Ragnaros 10 Heroic</p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/04854XqcfCY?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default BossTracker;
